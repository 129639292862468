import React from "react"

function Donate() {
    return (
        <>
            <title>Donate</title>
            <meta
                name="description"
                content="The amazing machines we make every year wouldn’t be possible without support from our amazing donors, check out how you can support us!"
            />
            <link rel="icon" type="image/x-icon" href="/Images/favicon.ico" />
            <meta charSet="UTF-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <link rel="stylesheet" href="W3Schools.css" />
            <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
            />
            <link rel="stylesheet" href="style.css" />
            <title>Purdue Rube Goldberg Club</title>
            <header style={{ position: "relative" }}>
                <div className="w3-top" id="home">
                    <div
                        className="w3-bar w3-large heeder-large-screen"
                        style={{ backgroundColor: "var(--navBar)", fontFamily: '"text"' }}
                    >
                        <div>
                            <a
                                href="./"
                                className="w3-bar-item w3-button w3-padding-large w3-hover-black"
                            >
                                Back To Main Page
                            </a>
                        </div>
                    </div>
                </div>
            </header>
            <main style={{ paddingBottom: 40 }}>
                <h2 className="title" style={{ color: "var(--highlightedText)" }}>
                    The amazing machines we make every year wouldn’t be possible without
                    support from our amazing donors, check out how you can support us!
                </h2>
                <div className="boxed-list">
                    <div className="game-image">
                        <img src="/TeamPhoto2024.webp" alt="Team" preload="none" />
                    </div>
                </div>
                <div className="boxed-list">
                    <div className="game-image">
                        <h2 className="title">Why Donate?</h2>
                        <h4 className="store-text-description">
                            We need every bit of help we can get to continue to operate at a high
                            level and build these complex machines on a yearly basis.
                            <br />
                            <br />
                            Every year our organization spends around $1,500, not a small amount
                            for a self-funded organization full of college students. Nearly all of
                            our funding comes from private donors, from parents and relatives to
                            friends who admire what we do, so every contribution we get directly
                            impacts our ability to continue to build incredible machines and give
                            back to the community around us. A donation of supplies is just as
                            beneficial to our team as a monetary donation; We go through a
                            significant amount of lumber every year and use an assortment of power
                            tools, electrical components, scrap metal, motors, and other random
                            items on machines every year, and who knows, if your generosity leads
                            you to donate items to the team, you may be able to spot your item on
                            our next machine! That being said, we do have yearly costs outside of
                            just tools and supplies, so please consider making a financial
                            donation as this will help us continue our work for years to come.
                            <br />
                            <br />
                        </h4>
                    </div>
                </div>
                <div className="boxed-list">
                    <div className="game-image">
                        <h2 className="title">How?</h2>
                        <h4 className="store-text-description">
                            Donors of the team will be recognized in various ways, from being
                            listed to here on our website to a potential workshop tour and beyond
                            depending on donation amount. The categories of recognition are shown
                            below:
                        </h4>
                        <h4>
                            <br />
                            Personal Donation:
                            <br />
                        </h4>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Donation</th>
                                    <th>Title</th>
                                    <th>Recognition</th>
                                </tr>
                                <tr>
                                    <td>$500+</td>
                                    <td>Machine Mastermind</td>
                                    <td>
                                        Listing on Website, T-shirt, and a Personal Tour of Workshop
                                    </td>
                                </tr>
                                <tr>
                                    <td>$250 - $500</td>
                                    <td>Module Architect</td>
                                    <td>Listing on Website, Personal Tour of Workshop</td>
                                </tr>
                                <tr>
                                    <td>$10 - $250</td>
                                    <td>Step Builder</td>
                                    <td>Listing on Website</td>
                                </tr>
                            </tbody>
                        </table>
                        <h4>
                            <br />
                            Corporate Donation:
                            <br />
                        </h4>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Donation</th>
                                    <th>Title</th>
                                    <th>Recognition</th>
                                </tr>
                                <tr>
                                    <td>$2500+</td>
                                    <td>Machine Mastermind</td>
                                    <td>
                                        Listing on Social Media and Website, Group Workshop Tour*,
                                        Sponsored Step, Name on T-shirt
                                    </td>
                                </tr>
                                <tr>
                                    <td>$1000 - $2500</td>
                                    <td>Module Architect</td>
                                    <td>
                                        Listing on Social Media and Website, Group Workshop Tour*, Name
                                        on T-shirt
                                    </td>
                                </tr>
                                <tr>
                                    <td>$100 - $1000</td>
                                    <td>Step Builder</td>
                                    <td>Listing on Website, Social Media, and T-shirt</td>
                                </tr>
                            </tbody>
                        </table>
                        <span>
                            <br />
                            *Listings and Logos on T-shirt can only be facilitated for donations
                            up to January 1st due to the production of the shirts after that date.
                            Recognition for donors will still appear in it’s entirety on the
                            website* <br />
                            <br />
                            **Tours of workshop are subject to Purdue's COVID-19 regulations
                            regarding outside visitors and may not be possible at all times.**
                        </span>
                    </div>
                </div>
                <div className="boxed-list">
                    <div className="game-image">
                        <h2 className="title">Ways to Give</h2>
                        <h4 className="store-text-description">
                            We appreciate your consideration of donating to the team! There are
                            multiple ways you can send in donations, and if you have any
                            questions, please contact us at&nbsp;
                            <a href="mailto: boilerrubegoldberg@gmail.com">
                                boilermakerchainreaction@gmail.com
                            </a>
                        </h4>
                        <h4 className="store-text-description">
                            <br />
                            Online:
                            <br />
                        </h4>
                        <h4 className="store-text-description">
                            1. Click on the online donation form below
                            <br />
                            2. Click on 'Click Here to Select Your Fund(s)'
                            <br />
                            3. Search for "Other" in the search bar
                            <br />
                            4. Select 'Other' and click continue
                            <br />
                            5. Under 'Please Specify Here' put "Boilermaker Rube Goldberg Team –
                            02367"
                            <br />
                            6. In the ‘Additional Gift Information’ section, check the ‘Additional
                            Comments/Special Instructions’ box
                            <br />
                            7. Type in "Boilermaker Rube Goldberg Team – 02367”
                            <br />
                            8. Click the 'Proceed to Payment' button
                            <br />
                            Questions? Email <a href="mailto: gifts@prf.org">gifts@prf.org</a> or
                            call <a href="tel: 18003192199">1-800-319-2199</a>
                        </h4>
                        <h4>
                            <center>
                                <a
                                    href="https://connect.purdue.edu/s/givenow?_ga=2.126116621.1282688306.1698024271-22115374.1593987365"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <button
                                        className="w3-btn w3-margin-top w3-round buttonOnBackground"
                                        style={{
                                            backgroundColor: "var(--btnBackground)",
                                            color: "var(--text)"
                                        }}
                                    >
                                        Online Donation Form
                                    </button>
                                </a>
                            </center>
                        </h4>
                        <h4 className="store-text-description">
                            <br />
                            Phone:
                            <br />
                        </h4>
                        <h4 className="store-text-description">
                            Donate using a credit card or gift card over the phone: Call{" "}
                            <a href="tel: 7654942727">765-494-2727</a>
                        </h4>
                        <h4 className="store-text-description">
                            <br />
                            Mail:
                            <br />
                        </h4>
                        <h4 className="store-text-description">
                            1. Click on the donation form below
                            <br />
                            2. Under 'Please designate my gift as indicated below' click on
                            'Other'
                            <br />
                            3. In the box next to 'other' put "Boilermaker Rube Goldberg Team"
                            <br />
                            Send to: <br />
                            Purdue Foundation, Gift Processing, <br />
                            P.O. Box 772401, Detroit, MI, 48277 2401
                        </h4>
                        <h4>
                            <center>
                                <a
                                    href="https://www.purdueforlife.org/app/uploads/giftpledgeform.pdf?_ga=2.159894589.1282688306.1698024271-22115374.1593987365"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <button
                                        className="w3-btn w3-margin-top w3-round buttonOnBackground"
                                        style={{
                                            backgroundColor: "var(--btnBackground)",
                                            color: "var(--text)"
                                        }}
                                    >
                                        Donation Form
                                    </button>
                                </a>
                            </center>
                        </h4>
                        <span>
                            *Follow mailing instructions on the Gift Pledge Form, do not mail the
                            form and check to the address listed at the bottom of the website
                            linked above*
                        </span>
                    </div>
                </div>
            </main>
        </>


    );
}

export default Donate;